import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBP1-GrlfM1MnmeosLm474Tiky9q1CDU74",
  authDomain: "affiliates-prod-999ca.firebaseapp.com",
  projectId: "affiliates-prod-999ca",
  storageBucket: "affiliates-prod-999ca.appspot.com",
  messagingSenderId: "1015947404050",
  appId: "1:1015947404050:web:b35978dbacc9304bf4c9cb",
  measurementId: "G-3VEX948P9",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
